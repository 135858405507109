<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="대관업무 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="LBLSAVEALL"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant
                  :required="true"
                  :editable="editable && !isOld"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  :required="true"
                  :editable="editable"
                  codeGroupCd="GOV_SCHEDULE_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="govScheduleTypeCd"
                  label="대관업무 구분"
                  v-model="data.govScheduleTypeCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="대관업무명"
                  name="govScheduleName"
                  v-model="data.govScheduleName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="editable"
                  label="관할기관"
                  name="inspTarget"
                  v-model="data.inspTarget">
                </c-text>
              </div>
              <!-- <div class="col-3">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="실시 주기"
                  name="govScheduleCycleTerm"
                  v-model="data.govScheduleCycleTerm">
                </c-text>
              </div>
              <div class="col-3">
                <c-select
                  :required="true"
                  :editable="editable"
                  :comboItems="cycleItems"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="govScheduleCycleCd"
                  label="실시 주기(단위)"
                  v-model="data.govScheduleCycleCd"
                ></c-select>
              </div> -->
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="LBLUSEFLAG"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-textarea
                  :editable="editable"
                  label="업무내용"
                  name="remarks"
                  :rows="3"
                  v-model="data.remarks">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <c-table
            ref="grid"
            title="대관업무 담당자"
            tableId="grid"
            :columns="grid.columns"
            :data="data.users"
            :isFullScreen="false"
            :columnSetting="false"
            :editable="editable"
            :filtering="false"
            gridHeight="352px"
            selection="multiple"
            rowKey="mngUserId"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn label="LBLADD" v-if="editable" icon="add" @btnClicked="addrow" />
                <c-btn label="LBLEXCEPT" v-if="editable" icon="remove" @btnClicked="removeRow" />
              </q-btn-group>
            </template>
          </c-table>
        </div> -->
        <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card title="검사관련 정보" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-4">
                <c-select
                  :editable="editable"
                  codeGroupCd="INSP_TYPE"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="inspType"
                  label="검사종류"
                  v-model="data.inspType"
                ></c-select>
              </div>
              <div class="col-4">
                <c-select
                  :editable="editable"
                  codeGroupCd="RELATED_LAWS_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="relatedLawsCd"
                  label="관련법규"
                  v-model="data.relatedLawsCd"
                ></c-select>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card title="계약서관련 정보" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-4">
                <c-select
                  :editable="editable"
                  codeGroupCd="CONT_TYPE"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="contType"
                  label="계약구분"
                  v-model="data.contType"
                ></c-select>
              </div>
              <div class="col-4">
                <c-select
                  :editable="editable"
                  codeGroupCd="CONT_STATUS"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="contStatus"
                  label="계약상태"
                  v-model="data.contStatus"
                ></c-select>
              </div>
              <div class="col-4">
                <c-text
                  :editable="editable"
                  label="계약업체"
                  name="contVendor"
                  v-model="data.contVendor">
                </c-text>
              </div>
            </template>
          </c-card>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="대관업무 관련 파일" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-12">
                <c-upload
                  :attachInfo="attachInfo"
                  :editable="editable"
                  label="대관업무 관련 파일 첨부파일">
                </c-upload>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'gov-schedule-info',
  props: {
    param: {
      type: Object,
      default: () => ({
        govScheduleMstId: '',
      }),
    },
    data: {
      type: Object,
      default: () => ({
        govScheduleMstId: '',  // 대관업무 마스터 일련번호
        govScheduleTypeCd: null,  // 대관업무구분
        plantCd: null,  // 사업장코드
        govScheduleName: '',  // 대관업무명
        govScheduleCycleCd: null,  // 법정 주기
        remarks: '',  // 내용
        useFlag: 'Y',  // 사용여부
        inspType: null,  // 검사-종류
        relatedLawsCd: null,  // 검사-관련법규
        inspTarget: '',  // 검사-대상 시설
        contStatus: null,  // 계약-상태
        contType: null,  // 계약-구분
        contVendor: '',  // 계약-업체
        users: [],
        deleteUsers: [],
      }),
    },
    attachInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'GOV_SCHEDULE_MST',
        taskKey: '',
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cycleItems: [
        { code: 'Y', codeName: '년' },
        { code: 'M', codeName: '개월' },
        { code: 'D', codeName: '일' },
      ],
      grid: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            align: 'center',
            sortable: false,
          },
          {
            name: 'mngUserName',
            field: 'mngUserName',
            label: 'LBLMANAGER',
            align: 'center',
            sortable: false,
          },
        ],
      },
      saveUrl: '',
      mappingType: 'POST',
      editable: true,
      isSave: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        width: '50%',
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.env.gov.mst.insert.url;
      // code setting
      // list setting
    },
    addrow() {
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.title = 'LBLUSER';
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (!this.data.users) this.data.users = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.data.users, { mngUserId: item.userId }) === -1) {
            this.data.users.splice(0, 0, {
              editFlag: 'C',
              govScheduleMstId: this.param.govScheduleMstId,
              mngUserId: item.userId,
              mngUserName: item.userName,
              deptName: item.deptName,
            });
          }
        });
      }
    },
    removeRow() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGEXCEPT', // 제외하시겠습니까?
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = transactionConfig.env.gov.user.delete.url;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$_.forEach(selectData, item => {
              // if (item.editFlag !== 'C') {
              //   this.$http.request(() => {
              //   this.data.users = this.$_.reject(this.data.users, item);
              //   },);
              // } else {
                this.data.users = this.$_.reject(this.data.users, item);
              // }
              this.$refs['grid'].$refs['compo-table'].clearSelection();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            })
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveInfo() {
      if (this.param.govScheduleMstId) {
        this.saveUrl = transactionConfig.env.gov.mst.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.env.gov.mst.insert.url;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.$set(this.param, 'govScheduleMstId', result.data.govScheduleMstId);
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType == 'POST') {
        this.$set(this.attachInfo, 'taskKey', result.data.govScheduleMstId)
        this.$set(this.attachInfo, 'isSubmit', uid())
      }
      this.$emit('getDetail');
    },
  }
};
</script>